<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ title }}</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Equipment Maintenance Information</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Truck Name</label>
                    <select :class="['form-control', {'is-invalid': errorFor('equipment_id')}]"
                            v-model="form.equipment_id">
                      <option value="">Select Equipment</option>
                      <option :value="equipment.id"
                              v-for="equipment in equipments"
                              :key="'equipment_'+equipment.id">{{ equipment.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('equipment_id')" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Date:</label>
                    <div class="input-group date"
                         id="date"
                         data-target-input="nearest">
                      <input type="text"
                             :class="['form-control datetimepicker-input', {'is-invalid': errorFor('date')}]"
                             v-model="form.date"
                             data-target="#date"/>
                      <div class="input-group-append"
                           data-target="#date"
                           data-toggle="datetimepicker">
                        <div class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </div>
                      </div>

                      <v-errors :errors="errorFor('date')" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Maintenance Type</label>
                    <select :class="['form-control', {'is-invalid': errorFor('type')}]"
                            v-model="form.type">
                      <option value="">Select Type</option>
                      <option value="Gas">Gas</option>
                      <option value="Service">Service</option>
                      <option value="Repair">Repair</option>
                      <option value="Supply">Supply</option>
                    </select>
                    <v-errors :errors="errorFor('type')" />
                  </div>
                </div>

                <div class="col-md-2" v-if="form.type === 'Service'">
                  <div class="form-group">
                    <label>Sub Service</label>
                    <select :class="['form-control', {'is-invalid': errorFor('sub_service')}]"
                            v-model="form.sub_service">
                      <option value="">Select Sub Service</option>
                      <option value="Truck oil change">Truck oil change</option>
                      <option value="Truck Trans">Truck Trans</option>
                      <option value="Truck Major Service">Truck Major Service</option>
                      <option value="TM Oil Change">TM Oil Change</option>
                      <option value="TM Pump Oil Change">TM Pump Oil Change</option>
                      <option value="TM Major Service">TM Major Service</option>
                      <option value="Weekly Inspection">Weekly Inspection</option>
                      <option value="Monthly Inspection">Monthly Inspection</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Current Millage</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('millage')}]"
                           v-model="form.millage">
                    <v-errors :errors="errorFor('millage')" />
                  </div>
                </div>

                <div class="col-md-2" v-if="form.sub_service.startsWith('TM')">
                  <div class="form-group">
                    <label>Current Hour</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('hour')}]"
                           v-model="form.hour">
                    <v-errors :errors="errorFor('hour')" />
                  </div>
                </div>

                <div class="col-md-2" v-if="form.sub_service.startsWith('TM')">
                  <div class="form-group">
                    <label>Next Service Hour</label>
                    <input type="number"
                           class="form-control"
                           v-model="form.next_service">
                  </div>
                </div>

                <div class="col-md-2" v-if="form.type === 'Service' && !form.sub_service.startsWith('TM')">
                  <div class="form-group">
                    <label>Next Service Millage</label>
                    <input type="number"
                           class="form-control"
                           v-model="form.next_service">
                  </div>
                </div>

                <div class="col-md-3" v-if="form.type === 'Supply'">
                  <div class="form-group">
                    <label>Product</label>

                    <select :class="['form-control', {'is-invalid': errorFor('product_id')}]"
                            v-model="form.product_id">
                      <option value="" selected>Select Product</option>

                      <option :value="product.id"
                              v-for="(product, i) in products"
                              :key="'product_'+i">{{ product.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('product_id')" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Amount</label>
                    <input type="text"
                           v-no-commas
                           :class="['form-control', {'is-invalid': errorFor('amount')}]"
                           v-model="form.amount">
                    <v-errors :errors="errorFor('amount')" />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Without HST</label>
                    <input type="text"
                           disabled
                           :value="(form.amount / 1.13).toFixed(2)"
                           class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Comment</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('comment')}]"
                           v-model="form.comment">
                    <v-errors :errors="errorFor('comment')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      :disabled="loading">Save</button>

              <router-link :to="{name: 'equipment_maintenances'}"
                           class="btn btn-secondary">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      title: 'Add Equipment Maintenance',
      trucks: [],
      machines: [],
      products: [],
      form: {
        equipment_type: 'truck',
        equipment_id: '',
        date: window.moment().format('YYYY-MM-DD'),
        sub_service: '',
        next_service: '',
        type: '',
        amount: '',
        millage: '',
        comment: '',
        hour: '',
        product_id: '',
      }
    }
  },
  computed: {
    equipments() {
      if (this.form.equipment_type === 'truck')
        return this.trucks;
      else if (this.form.equipment_type === 'machine')
        return this.machines;
      else
        return [];
    }
  },
  created() {
    this.axios.get('/products')
        .then((response) => {
          this.products = response.data.data;
        });

    this.axios.get('/trucks')
        .then((response) => {
          this.trucks = response.data.data;
        });

    this.axios.get('/machines')
        .then((response) => {
          this.machines = response.data.data;
        });

    if (this.$route.name === 'equipment_maintenances_edit') {
      this.axios.get('/equipment_maintenances/'+this.$route.params.id)
          .then((response) => {
            this.form = response.data.data;
            delete this.form.id;
          })

      this.title = 'Edit Equipment Maintenance';
    }
  },
  mounted() {
    let self = this;
    //Date picker
    window.$('#date').datetimepicker({
      format: 'YYYY-MM-DD'
    })

    window.$('#date').on("change.datetimepicker", function (e) {
      self.form.date = e.date.format('YYYY-MM-DD');
    });
  },
  methods: {
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.$route.name === 'equipment_maintenances_edit') {
        this.axios.patch('/equipment_maintenances/'+this.$route.params.id, this.form)
            .then(() => {
              this.$router.push({name: 'equipment_maintenances'});
              this.showSuccessMsg('Updated Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.axios.post('/equipment_maintenances', this.form)
            .then(() => {
              this.$router.push({name: 'equipment_maintenances'});
              this.showSuccessMsg('Added Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      }
    }
  }
}
</script>