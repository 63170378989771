<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-8">
          <h1 class="m-0">Next Maintenance</h1>
        </div><!-- /.col -->
        <div class="col-sm-4">
          <select class="select2"
                  id="equipments"
                  multiple="multiple"
                  data-placeholder="Select Trucks"
                  style="width: 100%;">
            <option :value="equipment.id"
                    v-for="equipment in equipments"
                    :key="'equipment_'+equipment.id">{{ equipment.name }}</option>
          </select>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered table-hover">
                  <thead>
                  <tr>
                    <th>Truck Name</th>
<!--                    <th>Type</th>-->
                    <th class="text-center">Current Hours/KM</th>
                    <th class="text-center">Engine Oil Change</th>
                    <th class="text-center">Pump / Trans</th>
                    <th class="text-center">Major Service</th>
<!--                    <th class="text-center">Transmission Flush</th>-->
                    <th class="text-center">Inspection</th>
                    <th>Tech Name</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-for="equipment in selectedEquipments"
                      :key="'equipment_'+equipment.id">
                    <td>{{ equipment.name }}</td>
<!--                    <td class="text-capitalize">{{ equipment.type }}</td>-->
                    <td class="text-center">{{ equipment.current }}</td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Engine Oil Change')]">
                        {{ getNextServiceDate(equipment, 'Engine Oil Change') }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span v-if="equipment.type === 'machine'"
                            :class="['badge', getBadgeColor(equipment, 'Pump Oil Change')]">
                        {{ getNextServiceDate(equipment, 'Pump Oil Change') }}
                      </span>
                      <span v-else
                            :class="['badge', getBadgeColor(equipment, 'Transmission Flush')]">
                        {{ getNextServiceDate(equipment, 'Transmission Flush') }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Major Service')]">
                        {{ getNextServiceDate(equipment, 'Major Service') }}
                      </span>
                    </td>
                    <td class="text-center">
                      <span :class="['badge', getBadgeColor(equipment, 'Inspection')]">
                        {{ getNextServiceDate(equipment, 'Inspection') }}
                      </span>
                    </td>
                    <td>
                      {{ equipment.maintenances.length ? equipment.maintenances[0].user?.name : '' }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      equipments: [],
      selectedEquipmentIds: []
    }
  },
  async created() {
    await this.axios.get('/next/equipment_maintenances')
        .then(res => {
          this.equipments = res.data.data;
        });
  },
  computed: {
    selectedEquipments() {
      if (!this.selectedEquipmentIds.length) {
        return this.equipments;
      }

      return this.equipments.filter(e => this.selectedEquipmentIds.includes(e.id.toString()));


    }
  },
  mounted() {
    let self = this;
    window.$('.select2').select2();

    window.$('#equipments').on('change.select2', function () {
      self.selectedEquipmentIds = window.$(this).val();
    });

    setTimeout(() => {
      this.selectedEquipmentIds = this.equipments.filter(e => e.include_in_default_report).map(e => e.id);
      window.$('#equipments').val(this.selectedEquipmentIds).trigger('change');
    }, 500)
  },
  methods: {
    getNextServiceDate(equipment, type) {
      const maintenance = equipment.maintenances.find(maintenance => maintenance.sub_service === type);

      if (maintenance) {
        return maintenance.next_service;
      }

      return '';
    },
    getBadgeColor(equipment, type) {
      const maintenance = equipment.maintenances.find(maintenance => maintenance.sub_service === type);
      let percentage = 100;

      if (maintenance && equipment.current) {
        const currentlyRemain = maintenance.next_service - equipment.current;
        const totalRemain = maintenance.next_service - maintenance.hour_millage;

        percentage = (currentlyRemain / totalRemain) * 100;

        if (percentage <= 5) {
          return 'badge-danger';
        } else if (percentage <= 15) {
          return 'badge-warning';
        }
      }

      return 'badge-success';
    }
  }
}
</script>