<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ title }}</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Truck Information</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('name')}]"
                           v-model="form.name">
                    <v-errors :errors="errorFor('name')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Operation Manager</label>
                    <select :class="['form-control', {'is-invalid': errorFor('operation_manager_id')}]"
                            v-model="form.operation_manager_id">
                      <option value="">Select Operation Manager</option>
                      <option :value="person.id"
                              v-for="person in operationManagers"
                              :key="'person_'+person.id">{{ person.name }}</option>
                    </select>

                    <v-errors :errors="errorFor('operation_manager_id')" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Millage</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('millage')}]"
                           v-model="form.millage">
                    <v-errors :errors="errorFor('millage')" />
                  </div>
                </div>
                <div :class="`${$route.name === 'settings_equipments_edit' ? 'col-md-3' : 'col-md-4'}`">
                  <div class="form-group">
                    <label>Frenchie %</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('frenchie_percentage')}]"
                           v-model="form.frenchie_percentage">
                    <v-errors :errors="errorFor('frenchie_percentage')" />
                  </div>
                </div>
                <div class="col-md-1" v-if="$route.name === 'settings_equipments_edit'">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-info form-control"
                            @click="showFrenchieHistory"><i class="fas fa-history"></i></button>
                  </div>
                </div>
                <div :class="`${$route.name === 'settings_equipments_edit' ? 'col-md-3' : 'col-md-4'}`">
                  <div class="form-group">
                    <label>Lease</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('lease')}]"
                           v-model="form.lease">
                    <v-errors :errors="errorFor('lease')" />
                  </div>
                </div>
                <div class="col-md-1" v-if="$route.name === 'settings_equipments_edit'">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-info form-control"
                            @click="showLeaseHistory"><i class="fas fa-history"></i></button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>407 ETR</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('etr_407')}]"
                           v-model="form.etr_407">
                    <v-errors :errors="errorFor('etr_407')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Plate #</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('plate_no')}]"
                           v-model="form.plate_no">
                    <v-errors :errors="errorFor('plate_no')" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Technicians</label>
                    <select class="select2"
                            id="technicians"
                            multiple="multiple"
                            data-placeholder="Select Technicians"
                            style="width: 100%;">
                      <option v-for="(technician, i) in technicians"
                              :key="'tech_'+i"
                              :value="technician.id">{{ technician.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-check">
                    <input type="checkbox"
                           value="1"
                           class="form-check-input"
                           v-model="form.exiled_from_report">
                    <label class="form-check-label">Exclude From Statistics</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Include in default Report</label>
                    <div class="form-group">
                      <div class="form-check d-inline mr-3">
                        <input class="form-check-input"
                               type="radio"
                               name="include_in_default_report"
                               value="1"
                               v-model="form.include_in_default_report">
                        <label class="form-check-label">Yes</label>
                      </div>
                      <div class="form-check d-inline">
                        <input class="form-check-input"
                               type="radio"
                               name="include_in_default_report"
                               value="0"
                               v-model="form.include_in_default_report">
                        <label class="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      :disabled="loading">Save</button>

              <router-link :to="{name: 'settings_equipments'}"
                           class="btn btn-secondary">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <FrenchiePercentageHistoryModal ref="frehchieHistoryModal" />
  <LeaseHistoryModal ref="leaseHistoryModal" />
</template>

<script>
import FrenchiePercentageHistoryModal from "@/views/settings/equipment/FrenchiePercentageHistoryModal.vue";
import LeaseHistoryModal from "@/views/settings/equipment/LeaseHistoryModal.vue";
export default {
  components: {FrenchiePercentageHistoryModal, LeaseHistoryModal},
  data() {
    return {
      title: 'Add Truck',
      companies: [],
      operationManagers: [],
      technicians: [],
      form: {
        technicianIds: [],
        companies: [],
        type: 'truck',
        lease: '',
        frenchie_percentage: '',
        include_in_default_report: '0',
        name: '',
        hour: '',
        etr_407: '',
        plate_no: '',
        operation_manager_id: '',
        exiled_from_report: false,
        millage: ''
      }
    }
  },
  watch: {
    form: {
      handler(){
        setTimeout(() => {
          this.initTechnicians();
        }, 10)
      },
      deep: true
    }
  },
  async created() {
    await this.loadTechnicians();

    this.axios.get('/operation-managers')
        .then((response) => {
          this.operationManagers = response.data.data;
        })

    if (this.$route.name === 'settings_equipments_edit') {
      this.axios.get('/equipments/' + this.$route.params.id)
          .then((response) => {
            this.form.type = response.data.data.type;
            this.form.name = response.data.data.name;
            this.form.frenchie_percentage = response.data.data.frenchie_percentage;
            this.form.lease = response.data.data.lease;
            this.form.include_in_default_report = response.data.data.include_in_default_report;
            this.form.etr_407 = response.data.data.etr_407;
            this.form.exiled_from_report = response.data.data.exiled_from_report;
            this.form.plate_no = response.data.data.plate_no;
            this.form.hour = response.data.data.hour;
            this.form.millage = response.data.data.millage;
            this.form.technicianIds = response.data.data.technicians.map(t => t.id);
            // this.form.companies = response.data.data.companies.map(c => c.id);

            /*setTimeout(() => {
              this.initCompanies();
            }, 10)*/
          })

      this.title = 'Edit Truck';
    }
  },
  mounted() {
    this.initTechnicians()
  },
  methods: {
    initTechnicians() {
      if (!window.$('#technicians').data('select2')) {
        let self = this;

        window.$('#technicians').select2();

        window.$('#technicians').val(self.form.technicianIds).trigger('change');

        window.$('#technicians').on('change.select2', function () {
          self.form.technicianIds = window.$(this).val();
        });
      }
    },
    async loadTechnicians() {
      await this.axios.get('/technicians')
          .then((response) => {
            this.technicians = response.data.data;
          });
    },
    showFrenchieHistory() {
      this.axios.get('/equipments/frenchie/histories/' + this.$route.params.id)
          .then((response) => {
            let histories = response.data.data;
            this.$refs.frehchieHistoryModal.show(histories);
          })
    },
    showLeaseHistory() {
      this.axios.get('/equipments/lease/histories/' + this.$route.params.id)
          .then((response) => {
            let histories = response.data.data;
            this.$refs.leaseHistoryModal.show(histories);
          })
    },
    /*initCompanies() {
      let self = this;

      window.$('.select2').select2({
        maximumSelectionLength: 1
      });

      window.$('#companies').val(self.form.companies).trigger('change');

      window.$('#companies').on('change.select2', function () {
        self.form.companies = window.$(this).val();
      });
    },*/
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.$route.name === 'settings_equipments_edit') {
        this.axios.patch('/equipments/'+this.$route.params.id, this.form)
            .then(() => {
              this.$router.push({name: 'settings_equipments'});
              this.showSuccessMsg('Updated Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.axios.post('/equipments', this.form)
            .then(() => {
              this.$router.push({name: 'settings_equipments'});
              this.showSuccessMsg('Added Successfully!')
            })
            .catch((err) => {
              this.allErrors = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
      }
    }
  }
}
</script>